import React, { useState, useEffect, createContext, ReactNode, useRef } from "react";
import { getKeycloak } from "./keycloak";
import { getConfig, ConfigType, defaultConfig, changeConfig } from "../config/config";
import { BackendApi } from "./api";
import { Alert, Modal } from "antd";

type MessageType = 'error' | 'info' | 'success' | 'warning';
// Define types for your context
interface ContextType {
  keycloak: {
    token: () => string | void;
    authenticated: boolean;
    authServerUrl?: string;
    realm: string;
    getCert: () => Promise<string | undefined>;
    login: (options: { redirectUri: string }) => any;
    logout: (options: { redirectUri: string }) => any;
  };
  config: ConfigType;
  isAdmin: boolean;
  api: BackendApi | undefined;
  message: (msg: string, type?: MessageType) => void;
  changeConfig: (env: string) => ConfigType | void;
  youSure: () => Promise<boolean>
}

// Create the context with an initial value
export const Context = createContext<ContextType>({
  keycloak: {
    realm: '',
    getCert: async () => '',
    token: () => { },
    authenticated: false,
    login: ({ redirectUri }: { redirectUri: string }) => {
      // Placeholder function; actual implementation provided later
    },
    logout: ({ redirectUri }: { redirectUri: string }) => {
      // Placeholder function; actual implementation provided later
    },
  },
  config: defaultConfig(),
  isAdmin: false,
  api: undefined,
  message: () => { },
  changeConfig: (env: string) => { },
  youSure: async () => false
});

// Define types for the props of your ContextWrapper component
interface ContextWrapperProps {
  children: ReactNode;
}

export function ContextWrapper({ children }: ContextWrapperProps) {
  const [config, setConfig] = useState<any>({});
  const [keycloak, setKeycloak] = useState<any>({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [api, setApi] = useState<any>({});
  const [messageText, setMessageText] = useState<string>("");
  const [messageType, setMessageType] = useState<MessageType>("info");
  const [showYouSure, setShowYouSure] = useState(false);
  const modalPromiseResolver = useRef<((value: boolean) => void) | null>(null);

  function message(text: string, type: MessageType = 'info') {
    setMessageType(type);
    setMessageText(text);
  }
  function chgConfig(env: string) {
    setConfig(JSON.parse(JSON.stringify(changeConfig(env))));
  }

  async function youSure(): Promise<boolean> {
    if (modalPromiseResolver.current) modalPromiseResolver.current = null;
    setShowYouSure(true);
    return new Promise((res) => {
      modalPromiseResolver.current = res;
    })
  }

  function modalAnswer(response: boolean) {
    if (modalPromiseResolver.current) {
      modalPromiseResolver.current(response);
      modalPromiseResolver.current = null;
    }
    setShowYouSure(false);
  }

  useEffect(() => {
    let cfg: ConfigType;

    getConfig()
      .then((conf) => {
        setConfig(conf);
        cfg = conf;
        const getKeycloakResult = getKeycloak(conf);
        return getKeycloakResult;
      })
      .then((getKeycloakResult) => {
        const kc = getKeycloakResult.keycloak;
        if (kc) {
          setKeycloak({
            authenticated: kc.authenticated,
            authServerUrl: kc.authServerUrl,
            realm: getKeycloakResult.realm,
            getCert: getKeycloakResult.getCert,
            token: () => kc.token,
            login: kc.login,
            logout: kc.logout
          })
        }
        setIsAdmin(getKeycloakResult.isAdmin);
        if (getKeycloakResult.keycloak) {
          setApi(new BackendApi({ keycloak: getKeycloakResult.keycloak, url: cfg.url.admin }))
        }
      });
  }, []);

  return (
    <Context.Provider value={{ youSure, config, keycloak, isAdmin, api, message, changeConfig: chgConfig }}>
      {children}
      {messageText && <div style={{ position: 'sticky', bottom: 0, width: '100%', left: 0 }}><Alert
        style={{ margin: 10 }}
        description={messageText.toString()}
        type={messageType}
        closable
        onClose={() => message("")}
      /></div>}
      <Modal open={showYouSure}
        title="Are you sure?"
        onOk={() => modalAnswer(true)}
        onCancel={() => modalAnswer(false)}
      >
      </Modal>
    </Context.Provider>
  );
}
