import { Button, Form, Input } from 'antd'
import React, { useContext, useState } from 'react'
import { Context } from '../context/context'
import { OnboardPatientDTO, OnboardPatientResponseOk } from '../context/api';
import ToClipboard from '../components/ToClipboard';


function Response({ response }: { response: OnboardPatientResponseOk }) {
  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ backgroundColor: '#ffffff', padding: 30, borderRadius: 10, boxShadow: '5px 5px 10px 5px rgba(0,0,0,0.1)' }}>
      <h3>New Patient</h3>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <div>Email: <ToClipboard>{response.email}</ToClipboard></div>
        <div>Patient ID: <ToClipboard>{response.patientId}</ToClipboard></div>
        <div>Fhir ID: <ToClipboard>{response.fhirId}</ToClipboard></div>
        <div>Consent ID: <ToClipboard>{response.consentId}</ToClipboard></div>
        <div>Inviting Org: <ToClipboard>{response.invitingOrg}</ToClipboard></div>
      </div>
    </div>
  </div>
}

export default function OnboardPatient() {
  const [working, setWorking] = useState(false)
  const [result, setResult] = useState<OnboardPatientResponseOk>()
  const { api, message } = useContext(Context);

  async function handleSubmit(e: OnboardPatientDTO) {
    if (!api) return;
    setWorking(true);

    try {
      const result = await api.onboardPatient(e);
      if (result.ok) {
        setResult(result);
        message('Patient onboarded')
      } else {
        message(`Could not onboard patient: ${result.message}`, 'error');
      }
    } catch (e: any) {
      message(e?.response?.data?.message || e.message || e.error || e, 'error')
    } finally {
      setWorking(false);
    }
  }
  return (
    <>
      <h2>Onboard Patient</h2>
      <Form onFinish={handleSubmit} style={{ maxWidth: 400 }}>
        <Form.Item label="Email" name="email" required colon>
          <Input required autoComplete="new-name" />
        </Form.Item>
        <Form.Item label="Password" name="password" required colon>
          <Input required type='password' autoComplete="new-password" />
        </Form.Item>
        <Form.Item label="Inviting Organization" name="invitingOrg" required colon>
          <Input required placeholder='FhirID of Organization' />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={working}>Onboard</Button>
        </Form.Item>
      </Form>

      {result && <Response response={result} />}

    </>
  )
}
