import { Button, Form } from 'antd'
import React, { useState } from 'react'
import SearchType from './SearchType'
import EmailOrId from './EmailOrId'
import { Context } from "../context/context";
import { getUserData, UserInfo } from '../requests/requests';

export type UserFinderType = {
  onUserFound: (user: UserInfo) => any
}
type Error = {
  message: string;
  infos?: string;
}
function UserFinder({ onUserFound }: UserFinderType) {
  const { keycloak, config } = React.useContext(Context);


  const [searchType, setSearchType] = useState("fhirId");
  const [dataOfUser, setdataOfUser] = useState("");
  const [error, setError] = useState<Error | undefined>();

  async function handleSubmit() {
    console.log(searchType, dataOfUser)
    try {
      const user = await getUserData(
        keycloak,
        config,
        dataOfUser,
        searchType
      ) as UserInfo;
      onUserFound(user);
    } catch (err: any) {
      setError({ message: `Could not find patient with ${searchType}:${dataOfUser}`, infos: err.toString() });
      // probably just not found... need to change admin-backend to handle errors properly
    }
  }

  return (
    <div>
      <Form onFinish={handleSubmit} >
        <SearchType
          defaultSearchType="fhirId"
          handleSearchTypeChange={type => setSearchType(type)}
        />

        <EmailOrId
          searchType={searchType}
          handleChange={e => setdataOfUser(e.target.value)}
        />
        <Form.Item>
          <Button htmlType="submit" type="primary">
            Find
          </Button>
        </Form.Item>
      </Form>
      <div>
        {error && <p className="error-message" title={error.infos}>
          {error.message}
        </p>}
      </div>
    </div>
  )
}

export default UserFinder