import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import { Button, Layout, Space, Image, Tag, Flex, Select } from "antd";
import { ReloadOutlined } from '@ant-design/icons';
import Start from "./pages/Start";
import { cleanUrl } from "./util/cleanUrl";
import "./styles/App.css";
import "./styles/form-styles.css";
import "./styles/Output.css";
import { useContext, useEffect, useState } from "react";
import { Context } from "./context/context";
import { CONFIGURED_ENVS } from "./config/config";

interface AppProps {
  unauthorized?: boolean;
}

function App({ unauthorized }: AppProps) {
  const SELECT_OPTIONS = CONFIGURED_ENVS.map(k => ({ value: k }));
  const { keycloak, isAdmin, api, config, changeConfig } = useContext(Context);
  const [online, setOnline] = useState(false);
  const [envChanged, setEnvChanged] = useState(false)
  const [showConfigChange, setShowConfigChange] = useState(false);
  const authenticated = keycloak.authenticated;

  useEffect(() => {
    if (!api?.checkBackendOnline) return;
    api.checkBackendOnline().then(state => setOnline(state));
  }, [api])

  useEffect(() => {
    setEnvChanged(config.env !== config.buildInfo?.env.APP_ENV);
  }, [config])

  const logout = () => {
    const url = cleanUrl();
    keycloak.logout({ redirectUri: url });
  };

  if (authenticated && !isAdmin && !unauthorized) {
    const urlUnauthorized = window.location.href + "/unauthorized";
    keycloak.logout({ redirectUri: urlUnauthorized });
  }

  const recheck = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    api?.checkBackendOnline().then(state => setOnline(state))
  };

  const changeEnd = (env: string) => {
    if (config.env === env) return;

    changeConfig(env);
    // the new end is stored in the session now
    // we changed all urls... all data get invalidated, so it's easy to just refresh all
    setTimeout(() => { window.location.reload() }, 100)
  }

  return (
    <Space className="App" direction="vertical" size={[0, 48]}>
      <Layout>
        <Sider className="Sider" theme="light" width={180}>
          <Image
            className="logo"
            src="../Teamedio-logo.png"
            alt="Logo of Teamedio"
          />
          <Flex vertical gap="large" align="center" style={{ marginTop: 20 }}>
            {authenticated && !unauthorized && (
              <Button danger onClick={logout}>
                Logout
              </Button>
            )}
            {online ? (
              <Tag color="lime" >Online</Tag>
            ) : (
              <Tag color="red" closeIcon={<ReloadOutlined />} onClose={recheck}>Offline</Tag>
            )}
            {showConfigChange ?
              <Select defaultValue={config.env} style={{ width: 100 }} options={SELECT_OPTIONS}
                onChange={changeEnd}
              ></Select>
              :
              <Tag color={envChanged ? 'yellow' : 'blue'} onClick={() => setShowConfigChange(true)}>
                {envChanged && '***'} {config?.env} {envChanged && '***'}
              </Tag>
            }
          </Flex>
        </Sider>
        <Layout>
          <Header className="Header">
            <h1>Teamedio Dashboard</h1>
          </Header>
          <Content>
            <Start unauthorized={unauthorized} />
          </Content>

        </Layout>
      </Layout>
    </Space>
  );
}

export default App;
