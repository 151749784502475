import { CopyOutlined, PaperClipOutlined } from "@ant-design/icons";
import React, { ReactNode, useEffect, useRef, useState } from "react";

type ToClipboardProps = {
  children: ReactNode; // The content to be wrapped
};

const ToClipboard: React.FC<ToClipboardProps> = ({ children }) => {
  const contentRef = useRef<HTMLDivElement>(null); // Reference to the wrapped content
  const [copySuccess, setCopySuccess] = useState(false);
  const [buttonColor, setButtonColor] = useState("#FFFFFF");

  const handleCopy = async () => {
    if (contentRef.current) {
      const content = contentRef.current.innerText.trim(); // Get the visible text and trim
      try {
        await navigator.clipboard.writeText(content); // Copy to clipboard
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 300); // Reset success message
      } catch (error) {
        alert("Failed to copy: " + error);
      }
    }
  };

  useEffect(() => {
    setButtonColor(copySuccess ? 'rgba(0,100,0,0.2)' : 'rgba(0,0,0,0)')
  }, [copySuccess])

  // Styles for the ToClipboard
  const styles = {
    area: {
      backgroundColor: buttonColor,
      border: 'none',
      transition: copySuccess ? 'none' : "background-color 0.5s ease", // Smooth transition
    },
    clip: {
      color: "#bbb",
    },
  };

  return (
    <span onClick={handleCopy} >
      {/* Reference the content to copy */}
      <span ref={contentRef} style={styles.area}>
        {children}
      </span>
      <CopyOutlined style={styles.clip} />
    </span>
  );
};



export default ToClipboard;
