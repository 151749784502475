import React, { useContext, useEffect, useState, version } from 'react'
import { Context } from '../context/context';
import { Button, Table } from 'antd';
import { VersionsDTO } from '../context/api';
import { ReloadOutlined } from '@ant-design/icons';

export default function VersionList() {
  const [loading, setLoading] = useState<boolean>(false);
  const [versions, setVersions] = useState<VersionsDTO[]>([]);
  const { api, youSure, message } = useContext(Context);

  useEffect(() => {
    reload();
  }, [api])

  async function reload() {
    if (!api) return;

    setLoading(true);
    try {
      const versions = await api.listVersions()
      setVersions(versions);
    } catch (e: any) {
      message(e.message || e.error || e, 'error')
    } finally {
      setLoading(false);
    }
  }

  async function deleteVersion(version: string) {
    if (!api) return;

    if (await youSure()) {
      const resp = await api.deleteVersion(version);

    }
  }

  async function deprecateVersion(version: string, deprecated: boolean) {
    if (!api) return;
    if (await youSure()) {
      const resp = await api.deprecateVersion(version, deprecated);
    }
  }

  const columns = [
    {
      title: "Versions",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "deprecated?",
      dataIndex: "deprecated",
      key: "deprecated",
      render: (val: boolean) => val && '❌',
    },
    {
      title: '',
      key: 'action',
      render: (_: any, item: VersionsDTO) => <div style={{ display: 'flex', gap: 20 }}>
        <Button color="danger" variant="outlined" onClick={() => deleteVersion(item.version)}>🗑️</Button>
        <Button color="danger" variant="outlined" onClick={() => deprecateVersion(item.version, !item.deprecated)}>{item.deprecated && 'un-'}deprecate</Button>
      </div>
    }
  ]


  return (
    <div>
      <Button onClick={reload} icon={<ReloadOutlined />}></Button>
      <Table loading={loading} columns={columns} dataSource={versions} pagination={false} rowKey='version' />
    </div>
  )
}
