import { Button, Card, Divider, Flex, Modal, Typography } from 'antd'
import { useContext, useEffect, useState } from 'react'
import UserFinder from './UserFinder';
import { User } from '../requests/requests';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import UserData from './UserData';
import UserIds from './UserIds';
import UserConsents from './UserConsents';
import UserDelete from './UserDelete';
import { Context } from '../context/context';
import UserFhirInfo from './UserFhirInfo';

const { Text } = Typography;



function UserInfo() {
  const { api, message } = useContext(Context);
  const [user, setUser] = useState<User>();
  const [deleteResult, setDeleteResult] = useState<any>();
  const [fixResult, setFixResult] = useState<any>();


  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Summary',
      children: (<>{displayUser()}</>),
    },
    {
      key: 'consents',
      label: 'Consents',
      children: <UserConsents user={user}></UserConsents>,
    },
    {
      key: 'ids',
      label: 'Ids',
      children: <UserIds showUser={user}></UserIds>,
    },
    {
      key: 'all',
      label: 'All Data',
      children: <UserData showUser={user}></UserData>,
    },
    {
      key: 'fhir',
      label: 'FHIR',
      children: <UserFhirInfo user={user}></UserFhirInfo>,
    },
    {
      key: 'delete',
      label: 'Delete User',
      children: <UserDelete showUser={user} onUserDeleted={(res) => setDeleteResult(res?.deletionResult)}></UserDelete>,
    },
  ];

  const onChange = (key: string) => {
    if (key === 'ids') {
      console.log('find data?')
    }
  };
  async function fixNoCases() {
    if (!api) return;
    const id = user?.pm?.baseData?.id;
    if (!id) {
      message(`No pm id could be found?`, 'error')
      return;
    }

    try {
      const results = await api.fixPatientWithoutCases(id)
      setFixResult(results[0].response.msg)
    } catch (e: any) {
      console.log(e)
      setFixResult(e?.response?.statusText)
    }
  }

  function clearUserInfo() {
    setUser(undefined);
    setFixResult(undefined);
    setDeleteResult(undefined);
  }

  function displayUser() {
    if (!user) return;

    const onboardedBy = user.pm?.baseData?.onboardedByOrgId
    const casesCount = user.pm?.cases?.length || 0;
    const howdiesCount = user.pm?.howdies?.length || 0;
    const fhirId = user.im.identityMaps.filter(im => im.destSpot == "FHIR")?.[0]?.idDest;
    const fhirUserType = user.im?.baseData?.fhirUserType;
    const errors = {
      noCases: casesCount == 0,
      noImData: user.im?.baseData === undefined,
      noPmData: user.pm?.baseData === undefined,
      any: false
    }
    errors.any = errors.noCases || errors.noImData || errors.noPmData;
    return (
      <div>
        <div><b>PM</b>: {user.pm?.baseData?.type} - {user.pm?.baseData?.state}</div>
        <div><b>FHIR</b>: {fhirId || 'None!'} - {fhirUserType}</div>
        <div>Onboarded by {onboardedBy || <Text italic>nobody!</Text>}</div>

        <br />
        {errors.any ?
          <div>
            Errors: <br />
            <b>
              {errors.noCases ? <div style={{ display: 'flex', gap: 10 }}>
                <div>No Cases!</div>
                <Button size='small' type='dashed' onClick={fixNoCases}>fix</Button>
                {fixResult && <div style={{ flex: 1, textAlign: 'end' }}>{fixResult}</div>}
              </div> : ''}
              {errors.noImData ? <div>No IM data!</div> : ''}
              {errors.noPmData ? <div>No PM data!</div> : ''}
            </b>
          </div>
          : ''}
        <br />

        <div>{casesCount} case(s)</div>
        <div>{`${howdiesCount} howdi(es)`}</div>
        <div style={{ fontSize: '70%' }}>
          <div>im created at: {user.im?.baseData?.createdAt}</div>
          <div>pm last changed at: {user.pm?.baseData?.updatedAt}</div>
        </div>

      </div>
    )
  }

  return (
    <div>
      <Card hoverable>
        <Modal open={deleteResult}
          cancelButtonProps={{ style: { display: 'none' } }}
          onOk={() => setDeleteResult(undefined)}
          onCancel={() => setDeleteResult(undefined)}
        >
          {JSON.stringify(deleteResult)}
          <div>
            PM: {deleteResult?.pm?.statusOk ? 'ok' : `Error: ${deleteResult?.pm?.message}`}
          </div>
          <div>
            IM: {deleteResult?.im?.statusOk ? 'ok' : `Error: ${deleteResult?.im?.message}`}
          </div>
          <div>
            Keycloak: {deleteResult?.kc?.statusOk ? 'ok' : `Error: ${deleteResult?.kc?.message}`}
          </div>
        </Modal>
        {user ? <>
          <Flex justify="space-between">
            <h4>{user.descriptor()}</h4>
            <Button danger shape="circle" type="primary" onClick={clearUserInfo}>X</Button>
          </Flex>
          <div>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          </div>
        </>
          :
          <UserFinder onUserFound={(foundUser: any) => setUser(foundUser)} />
        }
      </Card>
    </div>
  )
}

export default UserInfo;