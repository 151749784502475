import FixMissingCase from "../components/FixMissingCase";
import ForgotPass from "../components/ForgotPass";
import MissingCases from "../components/MissingCases";
import SendEmailTemplate from "../components/SendEmailTemplate";
import SyncKcPmEmails from "../components/SyncKcPmEmails";

export default function Fixes() {
  return <>
    <h2>Fixes and Maintenance</h2>
    <FixMissingCase />
    <MissingCases />
    <ForgotPass />
    <SendEmailTemplate />
    <SyncKcPmEmails />
  </>
}