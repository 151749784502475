import Keycloak from "keycloak-js";
import { User } from "../requests/requests";
import axios, { AxiosInstance } from "axios";
import { undefinedIfEmpty } from "../util/text";


export class BackendApi {
  private kc: Keycloak;
  private url: string;
  private client: AxiosInstance;
  private isBackendOnline = false;

  constructor({ url, keycloak }: { url: string; keycloak: Keycloak }) {
    this.kc = keycloak;
    this.url = url;
    this.client = axios.create({ baseURL: url });
    this.client.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${this.kc.token}`
      return config;
    })
  }

  async checkBackendOnline() {
    try {
      const response = await this.client.get("/");
      if (response.status === 200) {
        this.isBackendOnline = true;
      } else {
        this.isBackendOnline = false;
      }
    } catch (e) {
      this.isBackendOnline = false;
    }
    return this.isBackendOnline;
  }

  async OrgIds(fhirOrgIds: string[]) {
    if (!fhirOrgIds || fhirOrgIds.length === 0) return {};
    const response = await this.client.get("/admin/org-ids", {
      params: { fhirOrgIds },
    });
    return response.data;
  }

  async getIds(user: User): Promise<getIdsResultType[]> {
    const pmId = user.pm?.baseData?.id;
    if (!pmId) return [];
    const response = await this.client.get("/patient/emails-and-ids", {
      params: { pmId },
    });
    return response.data;
  }

  async getConsentsByUser(
    user: User,
    {
      year,
      month,
    }: {
      year?: number;
      month?: number;
    } = {}
  ) {
    const pmId = user.pm?.baseData?.id;
    if (!pmId) return [];

    // if no time span is specified, the current data from Fhir are requested, else from the consents db.
    if (!year && !month) {
      const response = await this.client.get("/patient/consents", {
        params: { pmId },
      });
      const patientConsents = response.data;
      return patientConsents;
    } else {
      const response = await this.client.get("/admin/consents-by-patient", {
        params: {
          pmId,
          year,
          month,
        },
      });
      const patients = response.data.data;
      return patients;
    }
  }

  async getConsentCounts(
    {
      organization,
      year,
      month,
    }: {
      organization?: string | number;
      year?: number;
      month?: number;
    } = {},
    {
      groupBy,
    }: {
      groupBy?: { organization?: boolean; year?: boolean; month?: boolean };
    } = {}
  ) {
    const params: any = { organization, year, month };
    if (groupBy?.organization) params["show-organization"] = true;
    if (groupBy?.year) params["show-year"] = true;
    if (groupBy?.month) params["show-month"] = true;
    const response = await this.client.get("/admin/historic-consents", {
      params,
    });
    return response.data;
  }

  async deleteUser(user: User, { partialDelete = false } = {}) {
    const response = await this.client.delete("/patient/user-data", {
      params: {
        pmId: user.pm.baseData.id,
        partialDelete,
      },
    });
    return response.data;
  }

  async getValidUsersWithoutCases(listFhirIds = false): Promise<NoCasesDto[]> {
    const response = await this.client.get("/admin/listMissingCases", {
      params: { listFhirIds },
    });
    return response.data;
  }

  async fixPatientWithoutCases(
    pmId?: string,
    fhirId?: string,
    options: { title?: string; description?: string } = {}
  ): Promise<fixMissingCasesDto[]> {
    const params: any = {
      pmId,
      fhirId,
      title: undefinedIfEmpty(options.title),
      description: undefinedIfEmpty(options.description),
    };

    const response = await this.client.post(
      "/admin/fixMissingCases",
      undefined,
      {
        params,
      }
    );
    return response.data;
  }

  async sendForgotEmail(email: string) {
    const response = await this.client.post(
      "/patient/send-forgot-email",
      { email },
    );
    return { response }
  }
  async sendEmailFromTemplate(email: string, template: number, variables: { [k: string]: any }) {
    const response = await this.client.post(
      "/admin/send-email-from-template",
      { email, template, variables },
    );
    return { response }
  }

  async syncEmail({ email, pmId }: { email?: string, pmId?: string }) {
    const response = await this.client.post(
      "/admin/sync-kc-pm-emails",
      undefined,
      {
        params: { email, pmId },
      }
    );
    return response.data
  }

  async listVersions(): Promise<VersionsDTO[]> {
    const response = await this.client.get('/version')
    return response.data;
  }

  async addVersion(version: string): Promise<VersionsDTO> {
    const response = await this.client.post('/version', { version })
    return response.data;
  }

  async deleteVersion(version: string) {
    const response = await this.client.delete(`/version/${version}`)
    return response.data;
  }

  async deprecateVersion(version: string, deprecated: boolean) {
    const response = await this.client.patch(`/version/${version}`, { deprecated })
    return response.data;
  }

  async listDevices({ userId, timePeriod }: { userId?: string, timePeriod?: number } = {}): Promise<DeviceDTO[]> {
    let since: string | undefined = undefined;
    if (timePeriod) {
      since = new Date(Date.now() - timePeriod * 24 * 60 * 60 * 1000).toISOString();
    }
    const response = await this.client.get('/device', { params: { user_id: userId, since } })
    return response.data;
  }
  async deviceStatistics(): Promise<DeviceStatisticsDTO> {
    const response = await this.client.get('/device/statistics-last-90-days')
    return response.data;
  }

  async onboardPatient({ email, password, invitingOrg }: OnboardPatientDTO): Promise<OnboardPatientResponseOk | OnboardPatientResponseError> {
    const response = await this.client.post('/patient/onboard', {
      email,
      password,
      invitingOrg
    })
    return response.data;
  }
  async getFhirStats(fhirId: string): Promise<FhirStatsDTO> {
    const response = await this.client.get(`/patient/fhir-stats/${fhirId}`)
    return response.data;
  }
}
export interface OnboardPatientResponseOk {
  ok: true;
  email: string;
  invitingOrg: string;
  patientId: string;
  fhirId: string;
  consentId: string;
  message?: string;
}
export interface OnboardPatientResponseError {
  ok: false;
  message: string;
}
export interface OnboardPatientDTO {
  email: string;
  password: string;
  invitingOrg: string;
}

export interface VersionsDTO {
  version: string;
  deprecated: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface fixMissingCasesDto {
  patientPmId: string;
  patientFhirId: string;
  response: {
    msg: string;
  };
}

export interface NoCasesDto {
  pmId: string;
  fhirId?: string;
}

export type getIdsResultType = {
  pmId: string;
};

export type DeviceDTO = {
  id: string
  remoteId: string
  name: string
  platform: string
  osVersion: string
  appVersion: string
  appBuildNumber: string
  lastLogin: string
  totalLogins: number
  createdAt: string
  updatedAt: string
}

export interface DeviceStatisticsDTO {
  counts: {
    total_logins: number
    total_devices: number
    total_users: number
    last_login_date: string
  }[]
  versions: {
    latest_app_version: string
    devices: number
    users: number
    last_login: string
  }[]
  span: {
    since: string,
    until: string
  }
}

export interface FhirStatsDTO {
  fhirId: string;
  Observation: {
    total: number;
    entries: {
      id: string;
      date: string;
    }[];
  };
  DiagnosticReport: {
    total: number;
    entries: {
      id: string;
      date: string;
      observationsInReport: number;
    }[];
  };
  MedicationAdministration: {
    total: number;
    entries: {
      id: string;
      date: string;
      medications: number;
    }[];
  };
  Communication: {
    total: number;
    entries: {
      id: string;
      date: string;
      from: string;
      to: string;
    }[];
  };
  QuestionnaireResponse: {
    total: number;
    entries: {
      id: string;
      date: string;
    }[];
  };
  Consent: {
    total: number;
    entries: {
      id: string;
      date: string;
      status: string;
      for: string;
    }[];
  };
}
