import React, { useEffect, useState } from 'react'

interface ShowResponseProps {
  children: React.ReactNode;
  isError?: boolean;
}

export default function ShowResponse({ children, isError }: ShowResponseProps) {
  const [show, setShow] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(isError || false);

  useEffect(() => {
    setShow(!!children)
  }, [children])


  return (
    <>
      {show && <div className={`${isError ? 'error' : 'success'}-message`}>{children}</div>}
    </>
  )
}
