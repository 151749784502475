import { Tabs, TabsProps } from "antd";
import DeviceList from "../components/DeviceList";
import DeviceStatistics from "../components/DeviceStatistics";

export default function Versions() {

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Device Logins',
      children: <DeviceList />,
    },
    {
      key: '2',
      label: 'Device Statistics',
      children: <DeviceStatistics />,
    }
  ];

  return <>
    <Tabs items={items} />
  </>
}