import { Button, Form, Input } from 'antd'
import React, { useContext } from 'react'
import { Context } from '../context/context'

export default function VersionAdd() {
  const { api, message } = useContext(Context)

  async function onFinish(data: any) {
    if (api) {
      try {
        const result = await api.addVersion(data.version);
        console.log(result)
        message('Version added')
      } catch (e: any) {
        message(e?.response?.data?.message || e.message || e.error || e, 'error')
      }
    }
  }
  return (
    <div style={{
      maxWidth: 400, padding: 20,
      borderRadius: 10, backgroundColor: '#fafafa', borderWidth: 1, borderColor: '#cccccc', borderStyle: 'solid',
      boxShadow: '2px 2px 5px 1px rgba(0,0,0,0.1)'
    }}>
      <Form
        onFinish={onFinish}
        layout="inline"
        autoComplete="off">
        <Form.Item name="version" label="Version">
          <Input />
        </Form.Item>
        <Form.Item label={null}>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
