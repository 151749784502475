import React, { useContext, useEffect, useState } from 'react'
import UserFinder from './UserFinder'
import { UserInfo } from '../requests/requests'
import { Button, Divider, Input } from 'antd';
import { Context } from '../context/context';

export default function SyncKcPmEmails() {
  const { api, message } = useContext(Context);

  const [synching, setSynching] = useState(false)
  const [user, setUser] = useState<UserInfo>()
  const [kcEmail, setKcEmail] = useState<string>()
  const [resultMsg, setResultMsg] = useState<string>()

  async function syncEmail({ pmId, kcEmail }: { pmId?: string, kcEmail?: string }) {
    setSynching(true);

    console.log('sync', { pmId, kcEmail });
    try {
      const res = await api?.syncEmail({ email: kcEmail, pmId })
      message(res.result.message, 'success')
    } catch (e: any) {
      message(e.toString(), 'error')
    }

    setUser(undefined);
    setSynching(false);
  }

  useEffect(() => {
    if (user) {
      const pmId = user.pm?.baseData?.id;
      if (pmId) {
        syncEmail({ pmId })
      }
    }
  }, [user])
  return (
    <>
      <Divider><h4>Sync E-mail between Keycloak and pm/im</h4></Divider>
      {resultMsg &&
        <p className="success-message">
          {resultMsg}
        </p>
      }
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {synching ?
          <div>Synching...</div>
          :
          <div style={{ display: 'flex', gap: 25 }}>
            <div>
              <div>Find User</div>
              <UserFinder onUserFound={setUser} />
            </div>
            <div>- OR - </div>
            <div>
              <div>Use KC E-mail</div>
              <div>
                <Input
                  style={{ width: 300 }}
                  onChange={(e) => setKcEmail(e.target.value)}
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <Button onClick={() => syncEmail({ kcEmail })} type="primary">Find</Button>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  )
}
