import axios from "axios";
import { getAuthConfig } from "../util/getAuthConfig";
import { getQueryParams } from "../util/getQueryParams";
import { log } from "../util/logger";

type ResponseArray = any[];

interface ResponseObject {
  [key: string]: any;
}

export const checkServices = async (
  keycloak: {
    authenticated: boolean;
    login: (options: { redirectUri: string }) => any;
  },
  config: { url: { admin: string } }
) => {
  const adminClient = axios.create({ baseURL: config.url.admin });
  try {
    const response = await adminClient.get("/monitoring/check-services", {
      headers: getAuthConfig(keycloak),
    });
    return response.data;
  } catch (err) {
    log("Error while fetching build infos: ", err);
    return { hasError: true };
  }
};
export type UserDataType = {
  fhirId?: string;
  pmId?: string;
  email?: string;
  kcId?: string;
};
export type ErrorType = {
  hasError: boolean;
};
export class UserData implements UserDataType {
  fhirId: string | undefined;
  pmId: string | undefined;
  email: string | undefined;
  kcId: string | undefined;

  constructor(resp: UserDataType) {
    this.fhirId = resp.fhirId;
    this.pmId = resp.pmId;
    this.email = resp.email;
    this.kcId = resp.kcId;
  }
  descriptor() {
    if (this.email) {
      return this.email;
    } else if (this.pmId) {
      return `pm:${this.pmId}`;
    } else if (this.fhirId) {
      return `fhir:${this.fhirId}`;
    } else {
      return `kb:${this.kcId}`;
    }
  }
}
// Good for searching emails otherwise use api.getIds from context
export const getEmailsAndIds = async (
  keycloak: any,
  config: { url: { admin: string } },
  searchTerm: string,
  searchType: string
): Promise<[UserDataType] | ErrorType> => {
  const adminClient = axios.create({ baseURL: config.url.admin });
  try {
    const response = await adminClient.get("/patient/emails-and-ids", {
      headers: getAuthConfig(keycloak),
      params: getQueryParams(searchTerm, searchType),
    });

    return response.data.map((res: UserDataType) => {
      return new UserData(res);
    });
  } catch (err) {
    log("Error while fetching emails and ids: ", err);
    // FIXME: let the exception bubble up
    return { hasError: true };
  }
};

export class User implements UserInfo {
  im: IM;
  pm: Pm;

  constructor(info: UserInfo) {
    this.im = info.im;
    this.pm = info.pm;
  }

  descriptor() {
    if (this.im?.baseData) {
      if (this.im.baseData?.eMail) return this.im.baseData.eMail;
      if (this.im.baseData?.username) return this.im.baseData.username;
    }
    if (this.pm?.baseData) {
      if (this.pm.baseData?.firstName || this.pm.baseData?.lastName) {
        return `${this.pm.baseData.lastName}, ${this.pm.baseData.firstName}`;
      }
      if (this.pm.baseData?.id) return `pm:${this.pm.baseData.id}`;
    }
    return "No info on user";
  }

  problems() {
    const errors: any = {};

    if (this.im?.baseData === undefined) errors.im = { missing: true };
    if (this.pm?.baseData === undefined) errors.pm = { missing: true };

    if (Object.keys(errors).length > 0) return errors;
  }

  get fhirId() {
    return this.im?.identityMaps?.find(m => m.destSpot === 'FHIR')?.idDest;
  }
}
export type UserInfo = {
  pm: Pm;
  im: IM;
};

export type IM = {
  baseData: IMBaseData;
  identityMaps: IdentityMap[];
  activationHashes: Activation[];
  activationPasswords: Activation[];
  consents: any[];
};

export type Activation = {
  id: number;
  hashValue?: string;
  createdAt: string;
  isActive: boolean;
  publicPassword?: string;
};

export type IMBaseData = {
  id: string;
  username: string;
  activationSecret: null;
  eMail: string;
  type: string;
  keyCloakId: null;
  state: string;
  fhirUserType: string;
  createdAt: string;
  updatedAt: string;
  invitedByOrganizationId: string;
};

export type IdentityMap = {
  idSource: string;
  sourceSpot: string;
  idDest: string;
  destSpot: string;
  entityName: string;
  state: string;
  type: string;
  createdAt: Date;
  updatedAt: Date;
};

export type Pm = {
  baseData: PmBaseData;
  organizationPatient: any[];
  organizationPatientFiles: any[];
  organizationPatientItems: any[];
  cases: Case[];
  howdies: any[];
};

export type Case = {
  id: string;
  authorId: string;
  patientId: string;
  fhirSynced: boolean;
  organizations: Organization[];
  title: string;
  status: "draft" | "created" | "assigned";
};

type Organization = {
  createdAt: string;
  isMarked: boolean;
  organizationId: string;
  status:
  | "assigned"
  | "not_assigned"
  | "in_progress"
  | "on_hold"
  | "pending"
  | "done"
  | "result_available"
  | "aborted";
};

export type PmBaseData = {
  id: string;
  type: string;
  firstName: string;
  lastName: string;
  gender: string;
  eMail: string;
  keyCloakId: string;
  state: string;
  createdAt: string;
  updatedAt: string;
  conditionSetting: null;
  birthdayAt: null;
  birthdayString: null;
  mobile: null;
  onboardedByOrgId: null;
  isDigitalCare: boolean;
};

export const getUserData = async (
  keycloak: any,
  config: { url: { admin: string } },
  searchTerm: string,
  searchType: string
): Promise<User> => {
  const adminClient = axios.create({ baseURL: config.url.admin });
  try {
    const response = await adminClient.get("/patient/user-data", {
      headers: getAuthConfig(keycloak),
      params: getQueryParams(searchTerm, searchType),
    });
    const data = response.data;
    return new User(data);
  } catch (err) {
    // do we need to catch it at all?
    log("Error while fetching user data: ", err);
    throw err;
  }
};

export const deleteUserData = async (
  keycloak: any,
  config: { url: { admin: string } },
  searchTerm: string,
  searchType: string
) => {
  const adminClient = axios.create({ baseURL: config.url.admin });
  try {
    const response = await adminClient.delete("/patient/user-data", {
      headers: getAuthConfig(keycloak),
      params: getQueryParams(searchTerm, searchType),
    });
    const userData = response.data;
    return userData;
  } catch (err) {
    log("Error while deleting user data: ", err);
    return { hasError: true };
  }
};

export const resetPassword = async (
  keycloak: any,
  config: { url: { admin: string } },
  searchTerm: string,
  searchType: string
) => {
  const adminClient = axios.create({ baseURL: config.url.admin });
  try {
    const response = await adminClient.post("/patient/reset-password", null, {
      params: getQueryParams(searchTerm, searchType),
      headers: getAuthConfig(keycloak),
    });
    const password = response.data.password;
    return { password };
  } catch (err) {
    log("Error while updating password: ", err);
    return { hasError: true };
  }
};

export const changeEmail = async (
  keycloak: any,
  config: { url: { admin: string } },
  requestData: { searchTerm: string; newEmail: any },
  searchType: string
) => {
  const adminClient = axios.create({ baseURL: config.url.admin });
  const { searchTerm, newEmail } = requestData;
  try {
    await adminClient.put(
      "/patient/change-email",
      { newEmail },
      {
        params: getQueryParams(searchTerm, searchType),
        headers: getAuthConfig(keycloak),
      }
    );
    return {};
  } catch (err) {
    log("An error occurred while trying to change email of patient: ", err);
    return { hasError: true };
  }
};

export const createOrg = async (
  keycloak: {
    authenticated: boolean;
    login: (options: { redirectUri: string }) => any;
  },
  config: { url: { admin: string } },
  requestData: { [key: string]: string; type: string }
) => {
  const adminClient = axios.create({ baseURL: config.url.admin });
  try {
    const response = await adminClient.post("/organization", requestData, {
      headers: getAuthConfig(keycloak),
    });
    const { password, fhirId } = response.data;
    return { password, fhirId };
  } catch (err) {
    log("Could not create group: ", err);
    return { hasError: true };
  }
};

export const countConsents = async (
  keycloak: {
    authenticated: boolean;
    login: (options: { redirectUri: string }) => any;
  },
  config: { url: { admin: string } }
) => {
  const adminClient = axios.create({ baseURL: config.url.admin });
  try {
    const response = await adminClient.get("/organization/count-consents", {
      headers: getAuthConfig(keycloak),
    });
    const consentCounts = response.data;
    return consentCounts;
  } catch (err) {
    log("Could not get count of consents: ", err);
    return { hasError: true };
  }
};

export const getSyncedStatus = async (
  keycloak: {
    authenticated: boolean;
    login: (options: { redirectUri: string }) => any;
  },
  config: { url: { message: any } },
  count: number | null
) => {
  const messageClient = axios.create({ baseURL: config.url.message });
  try {
    const response = await messageClient.get("/info/sync-status", {
      params: { count },
      headers: getAuthConfig(keycloak),
    });
    return response.data;
  } catch (err) {
    log("Error while requesting infos about synced messages: ", err);
    return { hasError: true };
  }
};

export const getOrgsFromPatientConsents = async (
  keycloak: any,
  config: { url: { admin: string } },
  requestData: { searchTerm: string; year: any; month: any },
  searchType: string
): Promise<ResponseObject | any[]> => {
  const { searchTerm, year, month } = requestData;
  const adminClient = axios.create({ baseURL: config.url.admin });
  try {
    // if no time span is specified, the current data from Fhir are requested, else from the consents db.
    if (!year && !month) {
      const response = await adminClient.get("/patient/consents", {
        params: getQueryParams(searchTerm, searchType),
        headers: getAuthConfig(keycloak),
      });
      const patientConsents = response.data;
      return patientConsents;
    } else {
      const response = await adminClient.get("/admin/consents-by-patient", {
        headers: getAuthConfig(keycloak),
        params: {
          ...getQueryParams(searchTerm, searchType),
          year,
          month,
        },
      });
      const patients = response.data.data;
      return patients;
    }
  } catch (err) {
    log("Error while requesting consents by patient: ", err);
    return { hasError: true };
  }
};

export const getPatientsFromOrgConsents = async (
  keycloak: any,
  config: { url: { admin: string } },
  requestData: { searchTerm: string; year: any; month: any }
): Promise<ResponseObject | any[]> => {
  const { searchTerm, year, month } = requestData;
  const fhirOrgId = searchTerm;
  const adminClient = axios.create({ baseURL: config.url.admin });
  try {
    const response = await adminClient.get(`/admin/${fhirOrgId}/patients`, {
      headers: getAuthConfig(keycloak),
      params: { year, month },
    });
    const patients = response.data.data;
    return patients;
  } catch (err) {
    log("Error while requesting consents by org: ", err);
    return { hasError: true };
  }
};

export const updateConsents = async (
  keycloak: {
    authenticated: boolean;
    login: (options: { redirectUri: string }) => any;
  },
  config: { url: { admin: string } }
) => {
  const adminClient = axios.create({ baseURL: config.url.admin });
  try {
    const response = await adminClient.post(
      "/admin/synchronize_consents",
      null,
      { headers: getAuthConfig(keycloak) }
    );
    if (response.status === 200) {
      return { result: "success", message: response?.data?.message };
    }
  } catch (err: any) {
    log("Error while updating consents: ", err);
    if (err.response?.status === 304) {
      return { result: "not-modified" };
    } else if (err.response?.status === 404) {
      return { result: "not-found", message: err.response?.data?.message };
    } else {
      return { result: "error", message: err?.toString() };
    }
  }
};

export const getServices = async (
  keycloak: any,
  config: { url: { admin: string } }
) => {
  const adminClient = axios.create({ baseURL: config.url.admin });
  try {
    const response = await adminClient.get("/monitoring/list-all-services", {
      headers: getAuthConfig(keycloak),
    });
    return response.data;
  } catch (err) {
    log("Error while fetching build infos: ", err);
    return { hasError: true };
  }
};

export const checkMessages = async (
  keycloak: {
    authenticated: boolean;
    login: (options: { redirectUri: string }) => any;
  },
  config: { url: { admin: string } },
  query: any
) => {
  console.log("Here is the config: ", config);
  const adminClient = axios.create({ baseURL: config.url.admin });
  try {
    const response = await adminClient.post("/message", null, {
      headers: getAuthConfig(keycloak),
      params: query,
    });
    return response.data;
  } catch (err) {
    log("Error while checking messages: ", err);
    return { error: err?.toString() };
  }
};
