import { Divider, Table } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../context/context';
import { DeviceStatisticsDTO } from '../context/api';

export default function DeviceStatistics() {
  const { api, message } = useContext(Context);
  const [statistics, setStatistics] = useState<DeviceStatisticsDTO>();

  useEffect(() => {
    if (!api || !message) return;
    api.deviceStatistics().then(r => setStatistics(r)).catch(r => message(r, 'error'))
  }, [api, message])

  if (!statistics) return null;

  return (
    <>
      <Divider><h4>Device Statistics</h4></Divider>
      <div style={{ fontSize: '10px', color: 'gray' }}>{statistics.span.since} - {statistics.span.until}</div>
      <DisplayCounts counts={statistics.counts} />
      <DisplayVersions versions={statistics.versions} />
    </>
  )
}

function DisplayCounts(props: { counts: DeviceStatisticsDTO['counts'] }) {
  const columns = [
    { title: 'Date', dataIndex: 'last_login_date', key: 'last_login_date' },
    { title: 'Total Logins', dataIndex: 'total_logins', key: 'total_logins' },
    { title: 'Total Devices logged in', dataIndex: 'total_devices', key: 'total_devices' },
    { title: 'Total Users logged in', dataIndex: 'total_users', key: 'total_users' },
  ];
  return <>
    <Divider><h5>Counts</h5></Divider>
    <Table dataSource={props.counts} columns={columns} />
  </>
}

function DisplayVersions(props: { versions: DeviceStatisticsDTO['versions'] }) {
  const columns = [
    { title: 'Latest App Version', dataIndex: 'latest_app_version', key: 'latest_app_version' },
    { title: 'Devices with this version', dataIndex: 'devices', key: 'devices' },
    { title: 'Users using this version', dataIndex: 'users', key: 'users' },
    { title: 'Last Login', dataIndex: 'last_login', key: 'last_login' },
  ];
  return <>
    <Divider><h5>Versions</h5></Divider>
    <Table dataSource={props.versions} columns={columns} />
  </>
} 
