import { Divider } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { Context } from '../context/context';
import { PreText } from './PreText';
import CopyButton from './CopyButton';

export default function KeycloakInfo() {
  const { keycloak } = useContext(Context);
  const [kcCert, setKcCert] = useState<string>();
  const [token, setToken] = useState<string>();

  useEffect(() => {
    if (!keycloak) return;
    keycloak.getCert().then(x => setKcCert(x))

    // this should be always the case if already here.
    const token = keycloak.token()
    if (token) setToken(token);
  }, [keycloak])


  return (
    <div>
      <Divider><h4>Keycloak</h4></Divider>
      <h2>token</h2>
      <CopyButton>
        <PreText>
          {token}
        </PreText>
      </CopyButton>
      <h2>cert</h2>
      <CopyButton>
        <PreText>
          {kcCert}
        </PreText>
      </CopyButton>

    </div>
  )
}
