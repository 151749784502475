import React, { ReactNode, useEffect, useRef, useState } from "react";

type CopyButtonProps = {
  children: ReactNode; // The content to be wrapped
};

const CopyButton: React.FC<CopyButtonProps> = ({ children }) => {
  const contentRef = useRef<HTMLDivElement>(null); // Reference to the wrapped content
  const [copySuccess, setCopySuccess] = useState(false);
  const [buttonColor, setButtonColor] = useState("#FFFFFF");

  const handleCopy = async () => {
    if (contentRef.current) {
      const content = contentRef.current.innerText.trim(); // Get the visible text and trim
      try {
        await navigator.clipboard.writeText(content); // Copy to clipboard
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 1000); // Reset success message
      } catch (error) {
        alert("Failed to copy: " + error);
      }
    }
  };

  useEffect(() => {
    setButtonColor(copySuccess ? '#007BFF' : '#FFFFFF')
  }, [copySuccess])

  // Styles for the CopyButton
  const styles = {
    wrapper: {
      position: "relative" as "relative", // Make the wrapper relatively positioned

    },
    button: {
      backgroundColor: buttonColor,
      position: "absolute" as "absolute", // Position the button in the top-right corner
      top: "-10px",
      right: "-10px",
      height: 30,
      width: 30,
      color: "#fff",
      border: "none",
      borderRadius: "50%",
      padding: "5px 10px",
      fontSize: "12px",
      cursor: "pointer",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
      zIndex: 10, // Ensure the button overlaps the content
      transition: copySuccess ? 'none' : "background-color 0.5s ease", // Smooth transition
    },
    content: {
      padding: "15px",
      borderRadius: "8px",
      border: "1px solid #ddd",
      backgroundColor: "#f7f9fc",
      fontFamily: "Courier New, monospace",
      whiteSpace: "pre-wrap" as "pre-wrap", // Preserve whitespace and line breaks
      wordWrap: "break-word" as "break-word",
    },
    successMessage: {
      marginTop: "10px",
      fontSize: "12px",
      color: "green",
    },
  };

  return (
    <div style={styles.wrapper}>
      {/* The button positioned to overlap the top-right corner */}
      <button onClick={handleCopy} style={styles.button} title="Copy to clipboard">
        📋 {/* Use an emoji or replace with an SVG/FontAwesome icon */}
      </button>

      {/* Reference the content to copy */}
      <div ref={contentRef} >
        {children}
      </div>
    </div>
  );
};



export default CopyButton;
