import React from "react";

export const PreText = ({ children }: { children: React.ReactNode }) => {
  return (
    <div style={{
      backgroundColor: "#f7f9fc",
      padding: "15px",
      borderRadius: "8px",
      border: "1px solid #ddd",
      overflowX: "auto",
    }}>
      <pre
        style={{
          whiteSpace: "pre-wrap", // Preserve line breaks and wrap text
          wordWrap: "break-word",
          fontFamily: "Courier New, monospace",
          fontSize: "14px",
          color: "#333",
          margin: 0,
          overflow: "hidden", // Hide overflow content
          textOverflow: "ellipsis", // Add ellipsis for overflow text
          display: "-webkit-box", // Flex-like behavior for multiline text
          WebkitBoxOrient: "vertical", // Set the box orientation
          WebkitLineClamp: 5, // Limit the number of lines to 3
        }}
      >
        {children}</pre>
    </div>
  );
};
