import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../context/context';
import { Button, Card, Divider, Select, Table } from 'antd';
import { CloseOutlined, ReloadOutlined } from '@ant-design/icons';
import UserFinder from './UserFinder';
import { UserInfo } from '../requests/requests';

export default function VersionList() {
  const [loading, setLoading] = useState<boolean>(false);
  const [devices, setDevices] = useState<any[]>([]);
  const [userId, setUserId] = useState<string>();
  const [timePeriod, setTimePeriod] = useState<number>(30);
  const { api, message } = useContext(Context);

  useEffect(() => {
    reload();
  }, [api, userId, timePeriod])

  async function reload() {
    if (!api) return;

    setLoading(true);
    try {
      const devices = await api.listDevices({ userId, timePeriod })
      setDevices(devices);
    } catch (e: any) {
      message(e.message || e.error || e, 'error')
    } finally {
      setLoading(false);
    }
  }

  const columns = [
    {
      title: "Version",
      dataIndex: "appVersion",
      key: "appVersion",
    },
    {
      title: "platform",
      dataIndex: "platform",
      key: "platform"
    },
    {
      title: "osVersion",
      dataIndex: "osVersion",
      key: "osVersion"
    },
    {
      title: "totalLogins",
      dataIndex: "totalLogins",
      key: "totalLogins"
    },
    {
      title: "lastLogin",
      dataIndex: "lastLogin",
      key: "lastLogin"
    }
  ]

  function onUserFound(user: UserInfo) {
    setUserId(user.pm.baseData.id);
  }


  return (
    <div>
      <Divider><h4>Device Logins</h4></Divider>
      <Card hoverable>
        {!userId ? <UserFinder onUserFound={onUserFound} /> :
          <div>
            {userId}
            <Button onClick={() => setUserId(undefined)} icon={<CloseOutlined />}></Button>
          </div>
        }
        <div>
          <div>Time period</div>
          <Select
            style={{ width: 150 }}
            value={timePeriod}
            options={[{ label: 'last 30 days', value: 30 }, { label: 'last 60 days', value: 60 }, { label: 'last 90 days', value: 90 }, { label: 'All', value: null }]}
            onChange={(value) => setTimePeriod(value)}
          />
        </div>
      </Card>
      <Button onClick={reload} icon={<ReloadOutlined />}></Button>
      <Table loading={loading} columns={columns} dataSource={devices} pagination={false} rowKey='id' />
    </div>
  )
}
