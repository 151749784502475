import React, { useEffect } from 'react'
import { User } from '../requests/requests'
import { Context } from '../context/context';
import { Divider, Table, Tabs } from 'antd';
import { FhirStatsDTO } from '../context/api';


function ObservationTable(props: { stats: FhirStatsDTO }) {
  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
  ];
  return <>
    <Divider>Observations ({props.stats.Observation.total})</Divider>
    <Table dataSource={props.stats.Observation.entries} columns={columns} pagination={false} />
  </>
}

function DiagnosticReportTable(props: { stats: FhirStatsDTO }) {
  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Observations', dataIndex: 'observationsInReport', key: 'observationsInReport' },
  ];
  return <>
    <Divider>DiagnosticReports ({props.stats.DiagnosticReport.total})</Divider>
    <Table dataSource={props.stats.DiagnosticReport.entries} columns={columns} pagination={false} />
  </>
}


function MedicationAdministrationTable(props: { stats: FhirStatsDTO }) {
  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Medications', dataIndex: 'medications', key: 'medications' },
  ];
  return <>
    <Divider>MedicationAdministration ({props.stats.MedicationAdministration.total})</Divider>
    <Table dataSource={props.stats.MedicationAdministration.entries} columns={columns} pagination={false} />
  </>
}

function CommunicationTable(props: { stats: FhirStatsDTO }) {
  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'From', dataIndex: 'from', key: 'from' },
    { title: 'To', dataIndex: 'to', key: 'to' },
  ];
  return <>
    <Divider>Communication ({props.stats.Communication.total})</Divider>
    <Table dataSource={props.stats.Communication.entries} columns={columns} pagination={false} />
  </>
}

function ConsentTable(props: { stats: FhirStatsDTO }) {
  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'For', dataIndex: 'for', key: 'for' },
  ];
  return <>
    <Divider>Consents ({props.stats.Consent.total})</Divider>
    <Table dataSource={props.stats.Consent.entries} columns={columns} pagination={false} />
  </>
}

export default function UserFhirInfo(props: { user: User | undefined }) {
  const { api } = React.useContext(Context);
  const [fhirStats, setFhirStats] = React.useState<FhirStatsDTO | null>(null);
  useEffect(() => {
    if (!props.user?.fhirId) return
    api?.getFhirStats(props.user.fhirId).then(setFhirStats)
  }, [props.user])

  if (!props.user?.fhirId) return <>No FHIR ID</>
  if (!fhirStats) return <>Loading...</>

  const items = [
    { label: 'Observations', key: '1', children: <ObservationTable stats={fhirStats} /> },
    { label: 'DiagnosticReports', key: '2', children: <DiagnosticReportTable stats={fhirStats} /> },
    { label: 'MedicationAdmnistration', key: '3', children: <MedicationAdministrationTable stats={fhirStats} /> },
    { label: 'Communication', key: '4', children: <CommunicationTable stats={fhirStats} /> },
    { label: 'Consents', key: '5', children: <ConsentTable stats={fhirStats} /> },

  ];
  return (
    <div>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  )
}